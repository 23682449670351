<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand navhome" href="/">聯繫我</a>
      <div class="navbar-collapse collapse">
          <ul class="navbar-nav">
              <li class="nav-item">
                <a  :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="/dailyPush">每日推薦</a>
              </li>
              <li class="nav-item">
                <a :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="/daily">每日班表</a>
              </li>
              <li class="nav-item">
                <a :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="/weekly">每周班表</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  其他功能
                </a>
                <ul class="bg-dark dropdown-menu" aria-labelledby="navbarDropdown">
                  <li ><a :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="/search">美容師搜尋</a></li>
                  <li ><a :class="{'nav-link' : navl, 'navfont': navf, 'navfont500': navf500}" href="/lastComment">最新心得</a></li>
                </ul>
              </li>
          </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<script>
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

export default {
  data() {
    return {
      navl: true,
      navf: false,
      navf500: false,
    };
  },
  created: function () {
    console.log("window=" + window.innerWidth);
    if (window.innerWidth < 500) {
      this.navf500 = true;
    } else if (window.innerWidth < 600) {
      this.navf = true;
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>

<style>
.navfont500 {
  font-size: 13px !important;
}
.navfont {
  font-size: 14px !important;
}

.navhome {
  font-size: 18px !important;;
  font-weight:bold;
  color:#F4D03F;
}
</style>
