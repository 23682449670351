import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '首頁'}
  },
  {
    path: '/daily',
    name: 'daily',
    component: () => import( '../views/DailyView.vue'),
    meta: { title: '每日班表'}
  },
  {
    path: '/weekly',
    name: 'weekly',
    component: () => import('../views/WeeklyView.vue'),
    meta: { title: '每週班表'}
  },
  {
    path: '/dailyPush',
    name: 'dailyPush',
    component: () => import('../views/DailyPushView.vue'),
    meta: { title: '每日推薦'}
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue'),
    meta: { title: '美容師搜尋'}
  },
  {
    path: '/comment',
    name: 'comment',
    component: () => import('../views/CommentView.vue'),
    meta: { title: '心得填寫'}
  },
  {
    path: '/lastComment',
    name: 'lastComment',
    component: () => import('../views/LastCommentView.vue'),
    meta: { title: '最新心得'}
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
