<template>
  <div class="home">
    <h1>台北舒壓按摩、每日班表、每日推薦 ❮每小時更新❯</h1>
    <br />
    <div v-html="contentHtml"></div>
    <br />
    <br />
    <h2 v-html="contactWord"></h2>
    <div class="container">
      <div v-if="home">
        &emsp;&emsp;Telegram:&emsp;&emsp;<a :href="tgLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="telegram"
        /></a>
        <br /><br />
        紅牌即時釋出頻道:&emsp;<a :href="tgLink2" target="_blank">
          <img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="telegram"
        /></a>
        <br /><br />
        舒壓預約專線:&emsp;<a :href="lineLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
        <br /><br />
        Line匿名社群:&emsp;<a :href="lineLink2" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
        <br /><br />
        美容師應徵專線:&emsp;<a :href="lineLink3" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
      </div>

      <div v-if="sub1">
        &emsp;&emsp;Telegram:&emsp;&emsp;<a :href="sub1tgLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="line"
        /></a>
      </div>

      <div v-if="sub2">
        &emsp;&emsp;Line:&emsp;&emsp;&emsp;<a :href="sub2lineLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
        <br /><br />
        老司機告示牌_定點:&emsp;<a :href="sub2tg1Link" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="line"
        /></a>
        <br /><br />
        外送茶指南:&emsp;<a :href="sub2tg2Link" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="line"
        /></a>
      </div>

      <div v-if="sub3">
        &emsp;&emsp;Telegram:&emsp;&emsp;<a :href="sub3tgLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="telegram"
        /></a>
        <br /><br />
        Telegram精選推薦:&emsp;<a :href="sub3tgLink2" target="_blank">
          <img
            src="https://imgf.gg9453.com/images/telegram.png"
            class="icon-size"
            alt="telegram"
        /></a>
        <br /><br />
        &emsp;&emsp;Line:&emsp;&emsp;&emsp;<a :href="sub3lineLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
        <br /><br />
        LINE精選推薦:&emsp;<a :href="sub3lineLink2" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
      </div>

      <div v-if="sub4">
        &emsp;&emsp;Line:&emsp;&emsp;&emsp;<a :href="sub4lineLink" target="_blank"
          ><img
            src="https://imgf.gg9453.com/images/line.png"
            class="icon-size"
            alt="line"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      tgLink: "",
      tgLink2: "",
      lineLink: "",
      lineLink2: "",
      lineLink3: "",
      home: true,
      sub1: false,
      sub2: false,
      sub3: false,
      sub4: false,
      sub1tgLink: "",
      sub2lineLink: "",
      sub2tg1Link: "",
      sub2tg2Link: "",
      sub3lineLink: "",
      sub3lineLink2: "",
      sub3tgLink: "",
      sub3tgLink2: "",
      sub4lineLink: "",
      contentHtml: "",
      contactWord: "聯繫方式",
    };
  },
  provide() {
    return {
      path: this.path,
    };
  },
  methods: {
    selectLink() {
      axios
        .post("/php/spa_config.php", {
          action: "selectLink",
        })
        .then((res) => {
          var findTg = res.data.find((e) => e.code === "tgLink");
          if (findTg) {
            this.tgLink = findTg.text;
          }

          var findTg2 = res.data.find((e) => e.code === "tgLink2");
          if (findTg2) {
            this.tgLink2 = findTg2.text;
          }

          var findLine = res.data.find((e) => e.code === "lineLink");
          if (findLine) {
            this.lineLink = findLine.text;
          }

          var findLine2 = res.data.find((e) => e.code === "lineLink2");
          if (findLine2) {
            this.lineLink2 = findLine2.text;
          }

          var findLine3 = res.data.find((e) => e.code === "lineLink3");
          if (findLine3) {
            this.lineLink3 = findLine3.text;
          }

          var sub1tg = res.data.find((e) => e.code === "sub1tg");
          if (sub1tg) {
            this.sub1tgLink = sub1tg.text;
          }

          var sub2line = res.data.find((e) => e.code === "sub2line");
          if (sub2line) {
            this.sub2lineLink = sub2line.text;
          }

          var sub2tg1 = res.data.find((e) => e.code === "sub2tg1");
          if (sub2tg1) {
            this.sub2tg1Link = sub2tg1.text;
          }

          var sub2tg2 = res.data.find((e) => e.code === "sub2tg2");
          if (sub2tg2) {
            this.sub2tg2Link = sub2tg2.text;
          }

          var sub3line = res.data.find((e) => e.code === "sub3line");
          if (sub3line) {
            this.sub3lineLink = sub3line.text;
          }

          var sub3line2 = res.data.find((e) => e.code === "sub3line2");
          if (sub3line2) {
            this.sub3lineLink2 = sub3line2.text;
          }

          var sub3tg = res.data.find((e) => e.code === "sub3tg");
          if (sub3tg) {
            this.sub3tgLink = sub3tg.text;
          }

          var sub3tg2 = res.data.find((e) => e.code === "sub3tg2");
          if (sub3tg2) {
            this.sub3tgLink2 = sub3tg2.text;
          }

          var sub4line = res.data.find((e) => e.code === "sub4line");
          if (sub4line) {
            this.sub4lineLink = sub4line.text;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createHtml: function () {
      if (window.innerWidth < 600) {
        this.contentHtml =
          "<h5>即時班表，森林與濕歌佐茶<br>願君舒壓得到一絲溫潤一杯茶</h5><br><h5>熱門推薦，一杯茶喜相逢<br>點一盞燈給茫然的老司機</h5><br><h5>台北舒壓、台北按摩、舒壓按摩<br>按摩排毒、淋巴排毒、美女按摩<br>林森北路正妹按摩、中山區紓壓放鬆<br>最完整的舒壓會館每日班表及台北舒壓客評心得就在這裏</h5>";
      } else {
        this.contentHtml =
          "<h5>即時班表，森林與濕歌佐茶，願君舒壓得到一絲溫潤一杯茶</h5><br><h5>熱門推薦，一杯茶喜相逢，點一盞燈給茫然的老司機</h5><br><h5>台北舒壓、台北按摩、舒壓按摩、按摩排毒、淋巴排毒、中山區紓壓排毒、林森北路正妹按摩、美女按摩，最完整的舒壓會館每日班表跟紅牌推薦以及台北舒壓客評心得就在這裏</h5>";
      }
    },
    resizeHandler: function () {
      this.createHtml();
    },
  },
  created: function () {
    this.selectLink();
    //console.log("window=" + window.location.origin);

    var location = window.location.origin;
    if (location.indexOf("9cz8s") > -1) {
      this.home = false;
      this.sub1 = true;
      this.sub2 = false;
      this.sub3 = false;
      this.sub4 = false;
    } else if (location.indexOf("g1fxk") > -1) {
      this.home = false;
      this.sub1 = false;
      this.sub2 = true;
      this.sub3 = false;
      this.sub4 = false;
    } else if (location.indexOf("opdh3") > -1) {
      this.home = false;
      this.sub1 = false;
      this.sub2 = false;
      this.sub3 = true;
      this.sub4 = false;
    } else if (location.indexOf("1azxs") > -1) {
      this.home = false;
      this.sub1 = false;
      this.sub2 = false;
      this.sub3 = false;
      this.sub4 = true;
    }

    if (this.home) {
      this.contactWord = "聯繫伊蝶娛樂";
    }
    window.addEventListener("resize", this.resizeHandler);
    this.createHtml();
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>

<style>
@import "bootstrap";
.icon-size {
  width: 90px;
  height: 90px;
}
@import "bootstrap";
.icon-size1 {
  width: 90px;
  height: 116px;
}
</style>
