import { createStore } from 'vuex'

export default createStore({
  state: {
    compw:''
  },
  getters: {
    getPw: function(state: any) {
      return state.compw;
    }
  },
  mutations: {
    insertPw: function(state: any, payload: object){
      state.compw = payload;
    }
  },
  actions: {
    setPw: function(context, payload: object){
      context.commit("insertPw", payload);
    }
  },
  modules: {
  }
})
