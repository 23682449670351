import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(ElementPlus)
app.use(Viewer, {
  defaultOptions: {
    title: false,
    navbar: false,
    toolbar: false,
    tooltip: false
  }
})
app.mount("#app");